.ContactDetails {
	.rows {
		margin-top: 40px;
		// position: relative;

		.row-wrapp {
			display: flex;
			align-items: flex-start;

			.row {
				margin-right: 20px;

				&:last-child {
					margin-right: 0;
				}
			}
		}

		.row {
			flex: 1;

			.items {
				display: flex;
				align-items: flex-start;
				margin-top: 20px;

				.phone_element {
					display: flex;
					width: 60px;
					height: 57px;
					padding: 8px 0px 8px 0px;
					justify-content: center;
					align-items: center;
					flex-shrink: 0;
					// border-radius: 4px 0px 0px 4px;
					border: 1px solid rgba(66, 69, 76, 0.20);
					background: #FFF;
		  
				  }
		  
				  .phone-field {
					width: 100%;
					border-radius: 0px 4px 4px 0px;
					border-top: 1px solid rgba(66, 69, 76, 0.2);
					border-right: 1px solid rgba(66, 69, 76, 0.2);
					border-bottom: 1px solid rgba(66, 69, 76, 0.2);
				  }
				  .menu-flags-button {
					border: none;
				  }
		  
				  .flags-select {
					background-color: #fff;
					width: 70px;
					height: 57px;
					padding-top: 5px;
					// padding-bottom: 10px;
					border-radius: 4px 0px 0px 4px;
					border-top: 1px solid rgba(66, 69, 76, 0.20);
					border-left: 1px solid rgba(66, 69, 76, 0.20);
					border-bottom: 1px solid rgba(66, 69, 76, 0.20);
					background: #FFF;
				  }
		  
				  .validate_input {
					display: flex;
					flex-direction: column;
		  
					.err {
					  border: 1px solid;
					  border-color: var(--error, #FF0D00);
					}
				  }
		  
				  .error_text {
					margin-left: 10px;
					display: flex;
					width: 288px;
					height: 18px;
					flex-direction: column;
					justify-content: center;
					color: var(--error, #FF0D00);
					font-family: Raleway;
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
				  }
		  
		  
				  .MuiInput-root {
					font-size: 12px;
					height: 32px;
					background: #ffffff;
					border: none;
					border-radius: 4px;
					padding: 8px 12px;
					outline: none;
					box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
					  0px 0px 1px rgba(0, 0, 0, 0.04);
		  
					&::placeholder {
					  color: rgba(66, 69, 76, 0.5);
					}
		  
					// ADD NEW BS COLOR
					&:focus {
					  box-shadow: 1px 0px 3px 1px #3f73f9;
					}
				  }

				.input-data {
					width: 295px;
					// background: #f0f1f3;
					background: #fff;
					margin-right: 20px;
					margin-bottom: 20px;

					&:last-child {
						margin-right: 0;
					}
					.copyIcon{
						position: absolute;
                        top: 17px;
                        right: 15px;
					}
				}
			}
		}
	}

	.actions {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		flex: 1;
		margin-top: 60px;

		.primary {
			width: 210px;
		}
	}
}
