.Checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  .mark {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000;

    &:not(.isSelected) {
      background: transparent;
      border: 3px solid #b1b1b1;
      border-radius: 2px;
    }
  }

  .circle {
    width: 14px;
    height: 14px;
    background: rgba(180, 180, 180, 0.1);
    border: 1px solid #ececec;
    border-radius: 2px;
  }

  .rectangle {
    min-width: 25px;
    min-height: 25px;
    border: 3px solid #b1b1b1;
    background: #ffffff;
    border-radius: 5px;
  }

  .isSelected {
    width: 14px;
    height: 14px;
    background: #3f73f9;
    border: 2px solid #3f73f9;
    border-radius: 3px;

    &:after {
      content: "";
      display: none;

      width: 5px;
      height: 8px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }

    &.circle {
      border-radius: 50%;
    }
  }

  .Typography {
    margin-left: 10px;
  }
}
