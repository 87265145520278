table {
  border-collapse: collapse;
}

tbody {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

th {
  display: flex !important;
  font-weight: normal !important;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin-bottom: 10px;
  text-align: center;
}

.table thead tr th:last-child {
  border: 0;
  margin-right: 20px;
}

.table tbody tr td:first-child {
  border-radius: 8px 0 0 8px;
}

.table tbody tr td:last-child {
  border-radius: 0 8px 8px 0;
  border: 0;
  margin-right: 20px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

td {
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-style: solid;
  border-left: 0;
  border-image: linear-gradient(rgba(255, 255, 255, 0) 23%, #3F73F9 23%, #3F73F9 77%, rgba(255, 255, 255, 0) 77%) 0% 100%;
  padding: 0.5rem 1rem 0.5rem 1rem;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: white;
}

.td {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
  }
}

.resizer {
  display: inline-block;
  background: linear-gradient(rgba(255, 255, 255, 0) 23%, #3F73F9 23%, #3F73F9 77%, rgba(255, 255, 255, 0) 77%) 0% 100%;
  ;
  width: 2.5px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 0;
  /* prevent scroll on touch devices */
  touch-action: none;
}

.campaings .table thead tr th:last-child {
  .resizer {
    display: none;
    background: linear-gradient(rgba(255, 255, 255, 0) 23%, #3F73F9 23%, #3F73F9 77%, rgba(255, 255, 255, 0) 77%) 0% 100%;
    ;
    width: 2.5px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 0;
    /* prevent scroll on touch devices */
    touch-action: none;
  }
}

.campaings .table thead tr th:first-child {
  .resizer {
    background: linear-gradient(rgba(255, 255, 255, 0) 23%, #3F73F9 23%, #3F73F9 77%, rgba(255, 255, 255, 0) 77%) 0% 100%;
    ;
    width: 2.5px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 0;
    /* prevent scroll on touch devices */
    touch-action: none;
  }
}

.isResizing {
  background: blue;
}