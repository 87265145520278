.SortPopup {
    position: relative;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 20px;
    display: inline-block;
  
    .label {
      display: flex;
      align-items: center;
  
      svg {
        transform: rotate(180deg);
  
        &.rotated {
          transform: rotate(0deg);
        }
      }
  
      span {
        margin: 0 5px;
        color: #3f73f9;
        cursor: pointer;
      }
    }
  
    .popup {
      position: absolute;
      right: 0;
      left: 75px;
      top: 10px;
      margin-top: 15px;
      background: #ffffff;
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.09);
      border-radius: 10px;
      overflow: hidden;
      padding: 10px 0;
      width: 160px;
      z-index: 1;
  
      ul {
        overflow: hidden;
        li {
          padding: 12px 20px;
          cursor: pointer;
  
          &.active,
          &:hover {
            background: rgba(163, 148, 142, 0.05);
          }
  
          &.active {
            font-weight: bold;
            color: blue;
          }
        }
      }
    }
  }
  