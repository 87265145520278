.Finance {
  .nav {
    margin: 40px 0;
    background-color: #f1f1f1;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 2px solid rgba(66, 69, 76, 0.1);

    a {
      color: rgba(66, 69, 76, 0.6);
      font-weight: 500;
      font-size: 14px;
      margin-right: 40px;
      position: relative;

      &.active {
        color: #42454c;
      }

      &.active::before {
        content: "";
        border-bottom: 3px solid #3f73f9;
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 100%;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width : 375px) {
  .Finance {
    .Typography {
      &.h1 {
        &.dark-gray {
          color: var(--colors-42454-c, #42454C);
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin-top: 10px;
        }
      }
    }

    .nav {
      display: flex;
      width: 288px;
      height: 45px;
      justify-content: center;
      align-items: center;
      border-radius: 4px 4px 0px 0px;
      border: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));
      background: var(--colors-f-9-f-9-f-9, #F9F9F9);
      margin-top: 12px;
      margin-bottom: 0;

      a {
        margin-right: 20px;
        white-space: nowrap;
        color: var(--colors-42454-c, #42454C);
        text-align: center;
        font-size: 11.755px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &.active::before {
          bottom: -16px;
        }
      }
    }
  }

  .Replenishment {
    display: flex;
    width: 288px;
    padding: 20.152px 13.434px;
    flex-direction: column;
    align-items: center;
    gap: 20.152px;
    border-radius: 0px 0px 4px 4px;
    border-right: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));
    border-bottom: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));
    border-left: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));
    background: var(--colors-f-9-f-9-f-9, #F9F9F9);

    .Button {
      display: flex;

      &.refill {
        width: 261px;
      }

      height: 27px;
      padding: 7.283px 97.058px 5.717px 102.942px;
      justify-content: flex-end;
      align-items: center;
      flex-shrink: 0;
    }
  }
}

@media screen and (min-width:375px) and (max-width : 768px) {
  .Finance {
    .Typography {
      &.h1 {
        &.dark-gray {
          color: var(--colors-42454-c, #42454C);
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin-top: 10px;
        }
      }
    }

    .nav {
      display: flex;
      width: 343px;
      height: 54px;
      justify-content: center;
      align-items: center;
      border-radius: 4px 4px 0px 0px;
      border: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));
      background: var(--colors-f-9-f-9-f-9, #F9F9F9);
      margin-top: 12px;
      margin-bottom: 0;

      a {
        margin-right: 20px;
        white-space: nowrap;
        color: var(--colors-42454-c, #42454C);
        text-align: center;
        font-size: 11.755px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &.active::before {
          bottom: -21px;
        }
      }
    }
  }

  .Replenishment {
    display: flex;
    width: 343px;
    padding: 20.152px 13.434px;
    flex-direction: column;
    align-items: center;
    gap: 20.152px;
    border-radius: 0px 0px 4px 4px;
    border-right: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));
    border-bottom: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));
    border-left: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));
    background: var(--colors-f-9-f-9-f-9, #F9F9F9);

    .Button {
      display: flex;

      &.refill {
        width: 311px;
        height: 32px;
        justify-content: center;
      }

      height: 27px;
      padding: 7.283px 97.058px 5.717px 102.942px;
      justify-content: flex-end;
      align-items: center;
      flex-shrink: 0;
    }
  }
}

@media screen and (min-width:768px) and (max-width : 1000px) {
  .Finance {
    .Typography {
      &.h1 {
        &.dark-gray {
          color: var(--colors-42454-c, #42454C);
          // font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin-top: 10px;
        }
      }
    }

    .nav {
      display: flex;
      width: 704px;
      height: 54px;
      justify-content: flex-start;
      align-items: center;
      border-radius: 4px 4px 0px 0px;
      border: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));
      background: var(--colors-f-9-f-9-f-9, #F9F9F9);
      margin-top: 12px;
      margin-bottom: 0;

      a {
        margin-left: 20px;
        margin-right: 0;
        white-space: nowrap;
        color: var(--colors-42454-c, #42454C);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &.active::before {
          bottom: -19px;
        }
      }
    }
  }

  .Replenishment {
    display: flex;
    width: 704px;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 0px 0px 4px 4px;
    border-right: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));
    border-bottom: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));
    border-left: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));
    background: var(--colors-f-9-f-9-f-9, #F9F9F9);

    .Button {
      display: flex;

      &.refill {
        width: 311px;
        height: 32px;
        justify-content: center;
      }

      height: 27px;
      padding: 7.283px 97.058px 5.717px 102.942px;
      justify-content: flex-end;
      align-items: center;
      flex-shrink: 0;
    }
  }
}