@media only screen and (max-width : 374px) {
  .SignUp {
    display: flex;
    background: #f0f1f3;
    // position: absolute;
    flex-direction: column;
    gap: 10px;
    padding-top: 20px;
    height: 150vh;
    // flex-wrap: wrap;
    align-items: center;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: 100vw;
    // overflow: hidden;

    .images {
      background-image: url("../../assets/images/bg-auth.png");
      position: relative;
      background-repeat: no-repeat;
      border-radius: 10px;
      width: 288px;
      height: 148px;
      padding-top: 57px;
      margin-bottom: 550px;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;

      .greetings {
        display: none;

        &-title {
          display: none;
        }
      }
    }

    .wrapp {
      position: absolute;
      width: 288px;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: 180px;

      .form {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-bottom: 380px;
        margin: auto auto;
        width: 288px;

        .form-title {
          font-size: 18px;
          font-weight: bold;
        }

        .sub-title {
          width: 288px;
          flex-wrap: wrap;
          opacity: 0.6;
          font-weight: normal;
          margin-bottom: 20px;
        }

        .email-field {
          width: 100%;
          position: relative;
          margin-top: -20px;
          // margin: auto auto; 
        }

        .password-field,
        .phone-field,
        .name-field {
          width: 100%;
          position: relative;
          // margin-top: -5px;
          // margin: auto auto; 
        }

        .MuiInput-root input{
          box-shadow: none;
        }

        .signUp-form {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 20px;
        }

        .password-field {
          &.err {
            margin-bottom: 10px;
          }
        }

        .phone_element {
          display: flex;
          width: 60px;
          height: 28px;
          padding: 8px 0px 8px 0px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          // border-radius: 4px 0px 0px 4px;
          border: 1px solid rgba(66, 69, 76, 0.20);
          background: #FFF;

        }

        .phone-field {
          border-radius: 0px 4px 4px 0px;
          border-top: 1px solid rgba(66, 69, 76, 0.2);
          border-right: 1px solid rgba(66, 69, 76, 0.2);
          border-bottom: 1px solid rgba(66, 69, 76, 0.2);
        }

        .menu-flags-button {
          border: none;
        }

        .flags-select {
          background-color: #fff;
          width: 70px;
          height: 28px;
          padding-bottom: 10px;
          border-radius: 4px 0px 0px 4px;
          border-top: 1px solid rgba(66, 69, 76, 0.20);
          border-left: 1px solid rgba(66, 69, 76, 0.20);
          border-bottom: 1px solid rgba(66, 69, 76, 0.20);
          background: #FFF;
        }

        .validate_input {
          display: flex;
          flex-direction: column;

          .err {
            border: 1px solid;
            border-color: var(--error, #FF0D00);
          }
        }

        .error_text {
          margin-left: 10px;
          display: flex;
          width: 288px;
          height: 18px;
          flex-direction: column;
          justify-content: center;
          color: var(--error, #FF0D00);
          font-family: Raleway;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .MuiInput-root {
          font-size: 12px;
          height: 32px;
          background: #ffffff;
          border: none;
          border-radius: 4px;
          padding: 8px 12px;
          outline: none;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
            0px 0px 1px rgba(0, 0, 0, 0.04);

          &::placeholder {
            color: rgba(66, 69, 76, 0.5);
          }

          // ADD NEW BS COLOR
          &:focus {
            box-shadow: 1px 0px 3px 1px #3f73f9;
          }
        }

        .helpfullText {
          display: none;
        }

        .field-radio {
          margin-top: -10px;
          display: flex;
          flex-direction: row;

          .MuiFormControlLabel-label {
            font-size: 12px;
          }

          // flex-wrap: wrap;
          // display: none;
        }

        .save-password {
          width: 288px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: -10px;

          .remember {
            display: flex;
            flex-direction: row;
            gap: 5px;
            flex-wrap: wrap;
            // align-items: center;
            cursor: pointer;

            .body1 {
              // margin-left: 10px;
            }

            .offerta {
              width: 250px;
              margin-left: 2px;
              flex-wrap: wrap;
              font-size: 12px;
            }

            button {
              font-size: 12px;
              // padding-left: 2px;
              color: #3f73f9;
              text-decoration: underline;
              margin-left: 5px;
            }
          }

          .forgot {
            color: #3f73f9;
          }
        }

        .btn-actions {
          width: 288px;
          margin-bottom: 60px;

          .Button {
            width: 100%;

            &.primary:disabled {
              background: #9ab5fb;
              color: #fff;
              cursor: default;
            }

            &.secondary:disabled {
              border: 1px solid #9ab5fb;
              color: #9ab5fb;
              cursor: default;
            }
          }

          .primary {
            margin-bottom: 10px;
          }
        }
      }

      .emailConfrim {
        background: rgba(63, 115, 249, 0.1);
        border: 1px solid #3F73F9;
        border-radius: 4px;
        padding: 10px;
        width: 288px;
        height: 28px;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 17px;
        display: flex;
        align-items: center;
        color: #3F73F9;
        // margin-top: 28px;
        margin-bottom: 28px;
      }

      .btnConfirm {
        width: 288px;
        height: 32px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 10px;
      }

      .textLink {
        width: 288px;
        height: 17px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #3F73F9;

        .link {
          text-decoration: underline;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          text-align: center;
          color: #3F73F9;
        }
      }
    }
  }
}

@media only screen and (min-width : 375px) and (max-width: 767px) {
  .SignUp {
    display: flex;
    background: #f0f1f3;
    // position: absolute;
    flex-direction: column;
    gap: 10px;
    padding-top: 20px;

    @media only screen and (max-height : 800px) {
      height: 800px
    }

    height:100vh;
    // flex-wrap: wrap;
    align-items: center;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    // width: 100vw;
    // overflow: hidden;

    .images {
      background-image: url("../../assets/images/bg-auth.png");
      position: relative;
      background-repeat: no-repeat;
      border-radius: 10px;
      width: 343px;
      height: 176px;
      padding-top: 67px;
      // margin-bottom: 550px;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;

      .greetings {
        display: none;

        &-title {
          display: none;
        }
      }
    }

    .wrapp {
      position: absolute;
      width: 343px;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      // top: 180px;

      .form {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-top: 200px;
        margin: auto auto;
        width: 343px;

        .form-title {
          font-size: 18px;
          font-weight: bold;
        }

        .sub-title {
          width: 343px;
          flex-wrap: wrap;
          opacity: 0.6;
          font-weight: normal;
          margin-bottom: 30px;
        }

        .email-field {
          max-width: 343px;
          position: relative;
          margin-top: -20px;
          // margin: auto auto; 
        }

        .password-field,
        .phone-field,
        .name-field {
          max-width: 343px;
          position: relative;
          // margin-top: -5px;
          // margin: auto auto; 
        }

        .signUp-form {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 20px;
        }

        .MuiInput-root input{
          box-shadow: none;
        }

        .password-field {
          &.err {
            margin-bottom: 10px;
          }
        }

        .phone_element {
          display: flex;
          width: 60px;
          height: 32px;
          padding: 8px 0px 8px 0px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          // border-radius: 4px 0px 0px 4px;
          border: 1px solid rgba(66, 69, 76, 0.20);
          background: #FFF;

        }

        .phone-field {
          width: 100%;
          border-radius: 0px 4px 4px 0px;
          border-top: 1px solid rgba(66, 69, 76, 0.2);
          border-right: 1px solid rgba(66, 69, 76, 0.2);
          border-bottom: 1px solid rgba(66, 69, 76, 0.2);
        }

        .menu-flags-button {
          border: none;
        }

        .flags-select {
          background-color: #fff;
          width: 70px;
          height: 32px;
          padding-bottom: 10px;
          border-radius: 4px 0px 0px 4px;
          border-top: 1px solid rgba(66, 69, 76, 0.20);
          border-left: 1px solid rgba(66, 69, 76, 0.20);
          border-bottom: 1px solid rgba(66, 69, 76, 0.20);
          background: #FFF;
        }

        .validate_input {
          display: flex;
          flex-direction: column;

          .err {
            border: 1px solid;
            border-color: var(--error, #FF0D00);
          }
        }

        .error_text {
          margin-left: 10px;
          display: flex;
          width: 288px;
          height: 18px;
          flex-direction: column;
          justify-content: center;
          color: var(--error, #FF0D00);
          font-family: Raleway;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .MuiInput-root {
          font-size: 12px;
          height: 32px;
          background: #ffffff;
          border: none;
          border-radius: 4px;
          padding: 8px 12px;
          outline: none;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
            0px 0px 1px rgba(0, 0, 0, 0.04);

          &::placeholder {
            color: rgba(66, 69, 76, 0.5);
          }

          // ADD NEW BS COLOR
          &:focus {
            box-shadow: 1px 0px 3px 1px #3f73f9;
          }
        }

        .helpfullText {
          display: none;
        }

        .field-radio {
          margin-top: -10px;
          display: flex;
          flex-direction: row;
          margin-bottom: 10px;

          .MuiFormControlLabel-label {
            font-size: 12px;
          }

          // flex-wrap: wrap;
          // display: none;
        }

        .save-password {
          width: 343px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: -30px;

          .remember {
            display: flex;
            flex-direction: row;
            gap: 5px;
            // align-items: center;
            cursor: pointer;

            .offerta {
              width: 343px;
              //margin-left: 20px;
              // margin-top: -15px;
              flex-wrap: wrap;
              font-size: 12px;
            }

            button {
              // margin-bottom: -20px;
              margin-left: 5px;
              font-size: 12px;
              // padding-left: 2px;
              color: #3f73f9;
              text-decoration: underline;
            }
          }

          .forgot {
            color: #3f73f9;
          }
        }

        .btn-actions {
          width: 343px;
          margin-bottom: 60px;

          .Button {
            width: 100%;

            &.primary:disabled {
              background: #9ab5fb;
              color: #fff;
              cursor: default;
            }

            &.secondary:disabled {
              border: 1px solid #9ab5fb;
              color: #9ab5fb;
              cursor: default;
            }
          }

          .primary {
            margin-bottom: 10px;
          }
        }
      }

      .emailConfrim {
        background: rgba(63, 115, 249, 0.1);
        border: 1px solid #3F73F9;
        border-radius: 4px;
        padding: 10px;
        width: 343px;
        height: 28px;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 17px;
        display: flex;
        align-items: center;
        color: #3F73F9;
        // margin-top: 28px;
        margin-bottom: 28px;
      }

      .btnConfirm {
        width: 343px;
        height: 32px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 10px;
      }

      .textLink {
        width: 343px;
        height: 17px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #3F73F9;

        .link {
          text-decoration: underline;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          text-align: center;
          color: #3F73F9;
        }
      }
    }
  }
}

@media only screen and (min-width : 768px) and (max-width: 1000px) {
  .SignUp {
    display: flex;
    background: #f0f1f3;
    // position: absolute;
    flex-direction: column;
    gap: 10px;
    padding-top: 20px;

    @media only screen and (max-height : 800px) {
      height: 800px
    }

    height:100vh;
    // flex-wrap: wrap;
    align-items: center;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    // overflow: hidden;

    .images {
      background-image: url("../../assets/images/bg-auth.png");
      position: relative;
      background-repeat: no-repeat;
      border-radius: 10px;
      width: 556px;
      height: 202px;
      padding-top: 77px;
      margin-bottom: 800px;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;

      .greetings {
        display: none;

        &-title {
          display: none;
        }
      }
    }

    .wrapp {
      position: absolute;
      width: 460px;
      max-height: 401px;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      // top: 180px;

      .form {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-top: 230px;
        margin: auto auto;
        width: 460px;

        .form-title {
          font-size: 22px;
          font-weight: bold;
        }

        .sub-title {
          width: 460px;
          flex-wrap: wrap;
          opacity: 0.6;
          font-weight: normal;
          margin-bottom: 30px;
        }

        .email-field {
          max-width: 460px;
          position: relative;
          margin-top: -20px;
          // margin: auto auto; 
        }

        .password-field,
        .phone-field,
        .name-field {
          max-width: 460px;
          position: relative;
          // margin-top: -5px;
          // margin: auto auto; 
        }

        .signUp-form {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 20px;
        }

        .MuiInput-root input{
          box-shadow: none;
        }

        .password-field {
          &.err {
            margin-bottom: 10px;
          }
        }

        .phone_element {
          display: flex;
          width: 60px;
          height: 32px;
          padding: 8px 0px 8px 0px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          // border-radius: 4px 0px 0px 4px;
          border: 1px solid rgba(66, 69, 76, 0.20);
          background: #FFF;

        }

        .phone-field {
          width: 100%;
          border-radius: 0px 4px 4px 0px;
          border-top: 1px solid rgba(66, 69, 76, 0.2);
          border-right: 1px solid rgba(66, 69, 76, 0.2);
          border-bottom: 1px solid rgba(66, 69, 76, 0.2);
        }
        .menu-flags-button {
          border: none;
        }

        .flags-select {
          background-color: #fff;
          width: 70px;
          height: 32px;
          padding-bottom: 10px;
          border-radius: 4px 0px 0px 4px;
          border-top: 1px solid rgba(66, 69, 76, 0.20);
          border-left: 1px solid rgba(66, 69, 76, 0.20);
          border-bottom: 1px solid rgba(66, 69, 76, 0.20);
          background: #FFF;
        }

        .validate_input {
          display: flex;
          flex-direction: column;

          .err {
            border: 1px solid;
            border-color: var(--error, #FF0D00);
          }
        }

        .error_text {
          margin-left: 10px;
          display: flex;
          width: 288px;
          height: 18px;
          flex-direction: column;
          justify-content: center;
          color: var(--error, #FF0D00);
          font-family: Raleway;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }


        .MuiInput-root {
          font-size: 12px;
          height: 32px;
          background: #ffffff;
          border: none;
          border-radius: 4px;
          padding: 8px 12px;
          outline: none;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
            0px 0px 1px rgba(0, 0, 0, 0.04);

          &::placeholder {
            color: rgba(66, 69, 76, 0.5);
          }

          // ADD NEW BS COLOR
          &:focus {
            box-shadow: 1px 0px 3px 1px #3f73f9;
          }
        }

        .helpfullText {
          display: none;
        }

        .field-radio {
          margin-top: -10px;
          display: flex;
          flex-direction: row;
          margin-bottom: 10px;

          .MuiFormControlLabel-label {
            font-size: 12px;
          }

          // flex-wrap: wrap;
          // display: none;
        }

        .save-password {
          width: 460px;
          display: flex;
          // align-items: center;
          // justify-content: space-between;
          margin-top: -30px;

          .remember {
            display: flex;
            flex-direction: row;
            gap: 5px;
            // align-items: center;
            cursor: pointer;

            .offerta {
              width: 460px;
              //margin-left: 20px;
              // margin-top: -15px;
              // flex-wrap: wrap;
              font-size: 12px;
            }

            button {
              // margin-bottom: -20px;
              margin-left: 5px;
              font-size: 12px;
              // padding-left: 2px;
              color: #3f73f9;
              text-decoration: underline;
            }
          }

          .forgot {
            color: #3f73f9;
          }
        }

        .btn-actions {
          width: 460px;
          margin-bottom: 60px;

          .Button {
            width: 100%;

            &.primary:disabled {
              background: #9ab5fb;
              color: #fff;
              cursor: default;
            }

            &.secondary:disabled {
              border: 1px solid #9ab5fb;
              color: #9ab5fb;
              cursor: default;
            }
          }

          .primary {
            margin-bottom: 10px;
          }
        }
      }

      .emailConfrim {
        background: rgba(63, 115, 249, 0.1);
        border: 1px solid #3F73F9;
        border-radius: 4px;
        padding: 10px;
        width: 460px;
        height: 28px;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 17px;
        display: flex;
        align-items: center;
        color: #3F73F9;
        // margin-top: 28px;
        margin-bottom: 28px;
      }

      .btnConfirm {
        width: 460px;
        height: 32px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 10px;
      }

      .textLink {
        width: 460px;
        height: 17px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #3F73F9;

        .link {
          text-decoration: underline;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          text-align: center;
          color: #3F73F9;
        }
      }
    }
  }
}

@media only screen and (min-width : 992px) {
  .SignUp {
    display: flex;
    align-items: center;
    height: 100vh;
    width: 100vw;
    overflow: hidden;

    .images {
      background-image: url("../../assets/images/bg-auth.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 50%;
      height: 100%;
      padding: 60px 40px;

      .greetings {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        font-family: Raleway;

        &-title {
          font-weight: bold;
          font-size: 64px;
          font-family: Raleway;
        }
      }
    }

    .wrapp {
      background: #f0f1f3;
      height: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .form {
        width: 460px;

        &-title {
          color: #09203E;
          font-family: Raleway;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin-bottom: 26px;
        }

        .sub-title {
          color: var(--primary-disabled, #09203E);
          font-variant-numeric: lining-nums proportional-nums;
          font-family: Raleway;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 20px;
        }

        .signUp-form {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }

        .email-field,
        .password-field,
        .phone-field,
        .name-field,
        .MuiInput-root {
          width: 100%;
          margin-bottom: 0;
          border-radius: 4px;
          border: 1px solid rgba(66, 69, 76, 0.20);
          background: #FFF;

          input {
            box-shadow: none;
          }
        }

        .phone-field {
          display: flex;
          width: 340px;
          height: 32px;
          padding: 8px 204px 8px 12px;
          align-items: center;
          border: none;
          border-radius: 0px 4px 4px 0px;
          border-top: 1px solid rgba(66, 69, 76, 0.20);
          border-right: 1px solid rgba(66, 69, 76, 0.20);
          border-bottom: 1px solid rgba(66, 69, 76, 0.20);
          background: #FFF;

          &.err {
            border-color: var(--error, #FF0D00);
            ;
          }
        }

        .password-field {
          &.err {
            margin-bottom: 10px;
          }
        }

        .phone_element {
          display: flex;
          width: 60px;
          height: 32px;
          padding: 8px 0px 8px 0px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          // border-radius: 4px 0px 0px 4px;
          border: 1px solid rgba(66, 69, 76, 0.20);
          background: #FFF;
        }

        .menu-flags-button {
          border: none;
        }

        .flags-select {
          background-color: #fff;
          width: 70px;
          height: 32px;
          padding-bottom: 10px;
          border-radius: 4px 0px 0px 4px;
          border-top: 1px solid rgba(66, 69, 76, 0.20);
          border-left: 1px solid rgba(66, 69, 76, 0.20);
          border-bottom: 1px solid rgba(66, 69, 76, 0.20);
          background: #FFF;
        }

        .validate_input {
          display: flex;
          flex-direction: column;

          .err {
            border: 1px solid;
            border-color: var(--error, #FF0D00);
          }
        }

        .error_text {
          margin-left: 10px;
          display: flex;
          width: 451px;
          height: 18px;
          flex-direction: column;
          justify-content: center;
          color: var(--error, #FF0D00);
          font-family: Raleway;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .helpfullText {
          color: #42454C;
          opacity: 50%;
          font-size: 10px;
          margin-left: 12px;
          width: 424px;
          position: relative;
          bottom: 8px;
        }

        .MuiInput-root {
          font-size: 12px;
          height: 32px;
          background: #ffffff;
          border: none;
          border-radius: 4px;
          padding: 8px 12px;
          outline: none;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
            0px 0px 1px rgba(0, 0, 0, 0.04);

          &::placeholder {
            color: rgba(66, 69, 76, 0.5);
          }

          // ADD NEW BS COLOR
          &:focus {
            box-shadow: 1px 0px 3px 1px #3f73f9;
          }
        }

        .field-radio {
          display: flex;
          gap: 65px;
          margin-left: 5px;
          white-space: nowrap;
        }

        .save-password {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .remember {
            display: flex;
            align-items: center;
            cursor: pointer;

            .offerta {
              width: 460px;
              color: var(--primary-disabled, #09203E);
              text-align: justify;
              font-variant-numeric: lining-nums proportional-nums;
              font-family: Raleway;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              cursor: default;

              button {
                background: linear-gradient(180deg, #6B58E0 0%, #3F73F9 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-variant-numeric: lining-nums proportional-nums;
                font-family: Raleway;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }
            }

            button {
              padding-left: 2px;
              color: #3f73f9;
              text-decoration: underline;
            }
          }
        }

        .btn-actions {
          margin-top: 20px;
          font-family: 'Raleway';

          .Button {
            width: 100%;

            &.primary:disabled {
              background: #9ab5fb;
              color: #fff;
              cursor: default;
            }

            &.secondary:disabled {
              border: 1px solid #9ab5fb;
              color: #9ab5fb;
              cursor: default;
            }
          }

          .primary {
            margin-bottom: 10px;
          }
        }
      }

      .emailConfrim {
        background: rgba(63, 115, 249, 0.1);
        border: 1px solid #3F73F9;
        border-radius: 4px;
        padding: 10px;
        width: 460px;
        height: 28px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        color: #3F73F9;
        margin-top: 28px;
        margin-bottom: 28px;
      }


      .confirm_text {
        width: 460px;
        color: var(--primary-disabled, #09203E);
        text-align: justify;
        font-variant-numeric: lining-nums proportional-nums;
        font-family: Raleway;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 30px;
      }

      .btnConfirm {
        width: 460px;
        height: 32px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        text-align: center;
        border-radius: 4px;
        background: linear-gradient(180deg, #6B58E0 0%, #3F73F9 100%);
        box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 10px 20px 0px rgba(0, 0, 0, 0.04);
        margin-bottom: 10px;
      }

      .textLink {
        width: 460px;
        height: 17px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #3F73F9;

        .link {
          text-decoration: underline;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          text-align: center;
          color: #3F73F9;
        }
      }
    }
  }
}