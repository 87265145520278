.controls {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;

  &_item {
    width: 200px;

    & > * {
      max-width: 100%;
    }
  }

  @media screen and (max-width: 1300px) {
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
}

.days-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 20px 50px;
  position: relative;

  .select_zone {
    position: absolute;
    border: 2px solid #3F73F9FF;
    background: transparent;
    border-radius: 5px;
  }

  .one_day {
    display: flex;
    align-items: center;
    gap: 10px;

    &.hour_list {
      position: sticky;
      bottom: 8px;
      background: transparent;
    }

    &.monday {
      margin-top: 10px;
    }

    .date_day {
      min-width: 50px;
      text-align: center;
      transition: all 0.5s ease-in-out;
      border-radius: 4px;
      cursor: pointer;
      user-select: none;
      text-transform: capitalize;

      &.work.active {
        color: white;
        background: #3f73f9;
      }

      &.weekend.active {
        color: white;
        background: #df3045;
      }

      &.work:not(.active):hover {
        color: white;
        background: #3f73f9;
      }

      &.weekend:not(.active):hover {
        color: white;
        background: #df3045;
      }
    }

    .clock_time {
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      user-select: none;
      background: white;
      transition: all 0.5s ease-in-out;

      &.active {
        background: #3f73f9;
        color: white;
      }

      &:not(.active):hover {
        background: #3f73f9;
        color: white;
      }
    }

    .hour {
      width: 25px;
      height: 25px;
      background: #dfdfdf;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease-in-out;

      &::before {
        content: attr(data-hour);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        color: transparent;
        font-size: 14px;
        transition: color 0.3s ease-in-out;
      }

      &.in_select_zone::before {
        color: white;
      }

      &:hover {
        cursor: pointer;

        &::before {
          color: black;
        }

        &.active_weekend::before,
        &.active_work::before {
          color: white;
        }
      }

      &.active_weekend {
        background: #df3045;
      }

      &.active_work {
        background: #3f73f9
      }
    }
  }

  @media screen and (max-width: 1300px) {
    padding: 20px 20px 20px 0;
    gap: 5px;
    .one_day {
      gap: 5px;

      .clock_time, .hour {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.list_selected_ranges {
  display: flex;
  gap: 5px;
  margin-bottom: 30px;

  .selected_range {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background: #dfdfdf;
    color: black;

    span {
      padding: 5px 10px;
    }
  }
}