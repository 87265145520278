.Optimisation {
  .container {
    padding: 0 40px;
  }

  .title {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .sub-title {
    font-weight: normal;
    opacity: 0.6;
  }

  .rows {
    margin-top: 40px;

    .row-wrapp {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 40px;
      width: calc(50% - 90px);
    }

    .row {
      .items {
        margin-top: 25px;

        input {
          width: 308px;
          background: #f0f1f3;
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }
        }

        .RadioButton {
          margin-bottom: 15px;

          .radio {
            margin-right: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    margin-top: 40px;
    height: 72px;
    background: #f9f9f9;
    padding: 0 40px;
    border-radius: 0 0 24px 24px;

    .wrapp {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 1;

      .secondary {
        margin-right: 20px;
      }
    }
  }
}
