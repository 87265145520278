.Select {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  height: 32px;
  width: 210px;

  

  &.white {
    background: #fff;
  }

  &.gray {
    background: #f0f1f3;

    .Dropdown-content {
      background: #f0f1f3;
      max-height: 99px;

      button {
        padding: 8px 12px;
      }
    }
  }

  .Dropdown {
    &-button {
      text-align: left;
      width: 100%;

      .select {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 8px 12px;

        .placeholder {
          color: rgba(66, 69, 76, 0.5);
        }

        span {
          margin-right: 5px;
        }
      }

      .placeholder-value {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    &-content {
      top: calc(100% + 2px);
      border-radius: 4px;
      width: 100%;
      max-height: 205px;
      overflow: auto;
      padding: 0;

      button {
        width: 100%;
        text-align: left;
        padding: 12px 20px;
        display: flex;
        align-items: center;

        span.rectangle {
          display: block;
          margin-right: 10px;
          width: 14px;
          height: 14px;
          border: 1px solid rgba(66, 69, 76, 0.2);
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;

          .rectangle-icon {
            display: none;
          }

          &.selected {
            background: rgba(63, 115, 249, 1);

            .rectangle-icon {
              display: block;
            }
          }
        }
      }
    }
  }
}