.Replenishment {
  .refill {
    width: 210px;
  }

  .Sort {
    border-bottom: 2px solid rgba(66, 69, 76, 0.1);
    padding-bottom: 40px;
    margin: 40px 0 0 0;
  }

  .line {
    height: 2px;
    width: 100%;
    background-color: #42454c;
    opacity: 0.1;
    margin: 40px 0;
  }

  .ReplenishmentTable {
    margin-top: 40px;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 10px;

    .DatePicker {
      margin-top: 20px;
      //margin-left: 20px;
      //margin-right: 20px;

      .date-picker {
        width: 280px;
        margin: 0;
      }
    }
  }
}

@media screen and (max-width : 375px) {
  .Replenishment {
    .container {
      .DatePicker {
        margin-left: 10px;

        .date-picker {
          width: 94px;
        }
      }
    }

    .line {
      margin: 0;
      width: 104px;
    }

    .ReplenishmentTable {
      margin-top: 0;
    }

    .css-ibnnmb {
      margin-left: 4px;
      margin-right: 4px;

      button {
        margin-left: 4px;

        svg {
          margin-left: 0;
          margin-right: 0;
        }
      }

      svg {
        margin-left: 4px;
        margin-right: 4px;
      }
    }
  }
}

@media screen and (min-width:375px) and (max-width : 768px) {
  .Replenishment {
    .container {
      .DatePicker {
        margin-left: 10px;

        .date-picker {
          width: 124px;
        }
      }
    }

    .line {
      margin: 0;
      width: 124px;
    }

    .ReplenishmentTable {
      margin-top: 0;
    }

    .css-ibnnmb {
      margin-left: 4px;
      margin-right: 4px;

      button {
        margin-left: 4px;

        svg {
          margin-left: 0;
          margin-right: 0;
        }
      }

      svg {
        margin-left: 4px;
        margin-right: 4px;
      }
    }
  }
}

@media screen and (min-width:768px) and (max-width : 1000px) {
  .Replenishment {
    .container {
      .DatePicker {
        .date-picker {
          width: 124px;
        }
      }
    }

    .line {
      margin: 0;
      width: 634px;
    }

    .ReplenishmentTable {
      margin-top: 0;
    }

    .css-ibnnmb {
      margin-left: 4px;
      margin-right: 4px;

      button {
        margin-left: 4px;

        svg {
          margin-left: 0;
          margin-right: 0;
        }
      }

      svg {
        margin-left: 4px;
        margin-right: 4px;
      }
    }
  }
}