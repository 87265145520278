@function calc-between-width($width-start, $value-start, $width-end, $value-end)
 { @return calc(#{$value-start} * 1px + (#{$value-end} - #{$value-start}) * (100vw - #{$width-start} * 1px) / (#{$width-end} - #{$width-start}))}
body {
    width: 100%;
    height: 100%;
    background: #E5E5E5;
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: column;

    .staff {
        .downald {
            display: flex;
            align-items: center;
            width: 105px;
            height: 32px;
            border: 1px solid #3F73F9;
            filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.04)) drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.04)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04));
            border-radius: 4px;
            font-family: 'Lato', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            justify-content: center;
            color: #3F73F9;
            margin-bottom: 25px;
        }

        .filters {
            display: flex;
            flex-direction: row;
            margin-bottom: 20px;
            margin-top: 50px;
            input::-webkit-input-placeholder {
                color: rgba(0, 0, 0, 0.6);
                opacity: 0.8; 
           }
           .MuiOutlinedInput-notchedOutline { border: 0 }
           
           .button {
            cursor: pointer;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            padding-top: 5px;
            color: #FFFFFF;
            width: 228px;
            height: 32px;
            background: #3F73F9;
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
            border-radius: 4px;
            margin-left: calc-between-width(1600, 480, 1920, 640);
        }

            .searchEmail {
                width: 117px;
                height: 32px;
                background: #FFFFFF;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 20px
            }

            .searchName {
                width: 202px;
                height: 32px;
                background: #FFFFFF;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 20px
            }
        }

        .table {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding-bottom: 40px;

            .tableHead {
                font-family: 'Lato', sans-serif;
                font-style: normal;
                font-size: 12px;
                line-height: 14px;
                display: flex;
                align-items: center;
                color: #42454C;
                width: 710px;

                .tr {
                    width: 200px;
                    display: flex;
                    justify-content: center;
                }
            }
            .container {
                height: 52.8vh;
                width: calc-between-width(1600, 910, 1920, 913);
                overflow:auto;

                .row {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    height: 40px;
                    width: 900px;
                    background: #FFFFFF;
                    box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.04);
                    border-radius: 9px;
                    margin-bottom: 10px;
                }
            }

            .td {
                text-align: center;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .check {
                width: 1px;
                height: 20px;
                background: #3F73F9;
                border-radius: 1px;
                transform: matrix(-1, 0, 0, 1, 0, 0);
            }
        }
    }

    .editButton {
        cursor: pointer;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        padding-top: 5px;
        color: #FFFFFF;
        width: 128px;
        height: 32px;
        background: #3F73F9;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
        border-radius: 4px;
        margin-left: 300px;
    }

    .buttonGroup {
        display: flex;
        flex-direction: row;
        margin-left: 200px;
        gap: 10px;

        .resetButton {
            cursor: pointer;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            padding-top: 5px;
            color: #FFFFFF;
            width: 128px;
            height: 32px;
            background: #42454C;
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
            border-radius: 4px;
            opacity: 70%;
        }

        .confirmButton {
            cursor: pointer;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            padding-top: 5px;
            color: #FFFFFF;
            width: 128px;
            height: 32px;
            background: #3F73F9;
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
            border-radius: 4px;
        }
    }
}