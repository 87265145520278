@media only screen and (min-width : 1000px) {
    .MainLayout {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    background: #f0f1f3;
    box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.03),
      0px 6.4px 14.4px rgba(0, 0, 0, 0.07);
    // overflow-y: auto;
    overflow: overlay;
    

    &-content {
      background: #f0f1f3;
      padding: 30px;
      width: 1440px;
      margin: 0 auto;
    }
  }
}

@media only screen and (max-width : 374px) {
  .MainLayout {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
    // max-width: 375px;
    width: 100vw;
    background: white;
    // background: #f0f1f3;
    box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.03),
      0px 6.4px 14.4px rgba(0, 0, 0, 0.07);
    overflow-y: auto;
    overflow-x: hidden;

    &-content {
      background: #fff;
      background-color: #fff;
      width: 288px;
      margin: 0 auto;
    }
  }
}

@media only screen and (min-width : 375px) and (max-width: 768px) {
  .MainLayout {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
    // max-width: 375px;
    width: 100vw;
    background: white;
    // background: #f0f1f3;
    box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.03),
      0px 6.4px 14.4px rgba(0, 0, 0, 0.07);
    overflow-y: auto;
    overflow-x: hidden;

    &-content {
      background: #fff;
      background-color: #fff;
      width: 343px;
      margin: 0 auto;
    }
  }
}

@media only screen and (min-width : 768px) and (max-width: 1000px) {
  .MainLayout {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
    // max-width: 375px;
    width: 100vw;
    background: #f0f1f3;
    // background: #f0f1f3;
    box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.03),
      0px 6.4px 14.4px rgba(0, 0, 0, 0.07);
    overflow-y: auto;
    overflow-x: hidden;

    &-content {
      background: #f0f1f3;
      width: 704px;
      margin: 0 auto;
    }
  }
}
