@media only screen and (max-width : 375px) {
  .PasswordResetConfirm {
    display: flex;
    background: #f0f1f3;
    // position: absolute;
    flex-direction: column;
    // flex-wrap: wrap;
    align-items: center;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    height: 100vh;
    width: 288px;
    font-family: Raleway;

    .images {
      background-image: url("../../assets/images/bg-auth.png");
      position: relative;
      background-repeat: no-repeat;
      border-radius: 10px;
      width: 288px;
      height: 148px;
      // padding-left: 47px;
      padding-top: 57px;
      margin-bottom: 550px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 10px;
      left: 0;
      right: 0;
      text-align: center;
      .greetings {
        display: none;
        &-title {
          display: none;
        }
      }
    }

    .wrapp {
      position: absolute;
      width : 288px;
      max-height: 255px;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: 230px;
  
      .form {
        display: flex;
        flex-direction: column;
        // padding-bottom: 380px;
        margin: auto auto;
        width: 288px;
  
        .form-title {
          width: 288px;
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 10px;
        }
  
        .sub-title {
          width: 288px;
          opacity: 0.6;
          font-weight: normal;
        }

        .email-field {
          width: 288px;
          // margin-bottom: 5px;
          &.err {
            border-color: var(--error, #FF0D00);
            ;
          }
        }

        .validate_input {
          display: flex;
          flex-direction: column;

          .err {
            border: 1px solid;
            border-color: var(--error, #FF0D00);
          }
        }

        .error_text {
          margin-left: 10px;
          display: flex;
          width: 288px;
          height: 28px;
          flex-direction: column;
          justify-content: center;
          color: var(--error, #FF0D00);
          font-family: Raleway;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .helpfullText {
          color: #42454C;
          opacity: 50%;
          font-size: 10px;
          margin-left: 12px;
          width: 288px;
          position: relative;
          bottom: 8px;
        }
  
        .MuiInput-root {
          max-width: 288px;
          font-size: 12px;
          height: 32px;
          background: #ffffff;
          border: none;
          border-radius: 4px;
          padding: 8px 12px;
          outline: none;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
            0px 0px 1px rgba(0, 0, 0, 0.04);
  
          &::placeholder {
            color: rgba(66, 69, 76, 0.5);
          }
  
          // ADD NEW BS COLOR
          &:focus {
            box-shadow: 1px 0px 3px 1px #3f73f9;
          }
        }
  
        .btn-actions {
          margin-top: 20px;
          width: 288px;
  
          .Button {
            width: 288px;
          }
  
          .primary {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width : 375px) and (max-width: 767px) {
  .PasswordResetConfirm {
    display: flex;
    background: #f0f1f3;
    align-items: center;
    @media only screen and (orientation:portrait) {
      position: relative;
    }
    align-content: center;
    // margin-bottom: 500px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    overflow: auto;
    font-family: Raleway;

    .images {
      background-image: url("../../assets/images/bg-auth.png");
      position: absolute;
      background-repeat: no-repeat;
      border-radius: 10px;
      width: 343px;
      height: 176px;
      padding-top: 67px;
      margin-top: 10px;
      // margin-bottom: 550px;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: 0px;
      text-align: center;
      .greetings {
        display: none;
        &-title {
          display: none;
        }
      }
    }

    .wrapp {
      position: absolute;
      width: 343px;
      max-height: 250px;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: 230px;
  
      .form {
        display: flex;
        flex-direction: column;
        // padding-bottom: 380px;
        margin: auto auto;
        width: 343px;
  
        .form-title {
          width: 343px;
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 10px;
        }
  
        .sub-title {
          width: 343px;
          opacity: 0.6;
          font-weight: normal;
        }

        .email-field {
          width: 343px;
          // margin-bottom: 5px;
          &.err {
            border-color: var(--error, #FF0D00);
            ;
          }
        }

        .validate_input {
          display: flex;
          flex-direction: column;

          .err {
            border: 1px solid;
            border-color: var(--error, #FF0D00);
          }
        }

        .error_text {
          margin-left: 10px;
          display: flex;
          width: 343px;
          height: 28px;
          flex-direction: column;
          justify-content: center;
          color: var(--error, #FF0D00);
          font-family: Raleway;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .helpfullText {
          color: #42454C;
          opacity: 50%;
          font-size: 10px;
          margin-left: 12px;
          width: 343px;
          position: relative;
          bottom: 8px;
        }
  
        .MuiInput-root {
          max-width: 343px;
          font-size: 12px;
          height: 32px;
          background: #ffffff;
          border: none;
          border-radius: 4px;
          padding: 8px 12px;
          outline: none;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
            0px 0px 1px rgba(0, 0, 0, 0.04);
  
          &::placeholder {
            color: rgba(66, 69, 76, 0.5);
          }
  
          // ADD NEW BS COLOR
          &:focus {
            box-shadow: 1px 0px 3px 1px #3f73f9;
          }
        }
  
        .btn-actions {
          margin-top: 20px;
          width: 343px;
  
          .Button {
            width: 343px;
          }
  
          .primary {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width : 768px) and (max-width: 1000px) {
  .PasswordResetConfirm {
    background: #f0f1f3;
    @media only screen and (orientation:portrait) {
      position: relative;
    }
    // position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    max-height: 100vh;
    overflow: auto;
    font-family: Raleway;

    .images {
      background-image: url("../../assets/images/bg-auth.png");
      position: absolute;
      top: 0px;
      background-repeat: no-repeat;
      border-radius: 10px;
      width : 556px;
      height: 202px;
      // padding-left: 47px;
      margin-top: 10px;
      padding-top: 77px;
      // margin-bottom: 800px; //790px
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
      .greetings {
        display: none;
        &-title {
          display: none;
        }
      }
    }

    .wrapp {
      position: absolute;
      width : 460px;
      max-height: 401px;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      margin-top: 30px;
      // top: 330px;//300px;
      padding-top: -10px;
      top: 300px;

      .form {
        display: flex;
        flex-direction: column;
        padding-bottom: 240px;
        margin: auto auto;
        width : 460px;
  
        .form-title {
          width: 460px;
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 10px;
        }
  
        .sub-title {
          width: 460px;
          opacity: 0.6;
          font-weight: normal;
        }

        .email-field {
          width: 100%;
          // margin-bottom: 5px;
          &.err {
            border-color: var(--error, #FF0D00);
            ;
          }
        }

        .validate_input {
          display: flex;
          flex-direction: column;

          .err {
            border: 1px solid;
            border-color: var(--error, #FF0D00);
          }
        }

        .error_text {
          margin-left: 10px;
          display: flex;
          width: 451px;
          height: 18px;
          flex-direction: column;
          justify-content: center;
          color: var(--error, #FF0D00);
          font-family: Raleway;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .helpfullText {
          color: #42454C;
          opacity: 50%;
          font-size: 10px;
          margin-left: 12px;
          width: 424px;
          position: relative;
          bottom: 8px;
        }
  
        .MuiInput-root {
          max-width: 460px;
          font-size: 12px;
          height: 32px;
          background: #ffffff;
          border: none;
          border-radius: 4px;
          padding: 8px 12px;
          outline: none;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
            0px 0px 1px rgba(0, 0, 0, 0.04);
  
          &::placeholder {
            color: rgba(66, 69, 76, 0.5);
          }
  
          // ADD NEW BS COLOR
          &:focus {
            box-shadow: 1px 0px 3px 1px #3f73f9;
          }
        }
  
        .btn-actions {
          margin-top: 20px;
          width: 460px;
  
          .Button {
            width: 460px;
          }
  
          .primary {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width : 1000px) {
  .PasswordResetConfirm {
    display: flex;
    align-items: center;
    height: 100vh;
    overflow: hidden;
    font-family: Raleway;

    .images {
      background-image: url("../../assets/images/bg-auth.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 50%;
      height: 100%;
      padding: 60px 40px;

      .greetings {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;

        &-title {
          font-weight: bold;
          font-size: 64px;
        }
      }
    }

    .wrapp {
      background: #f0f1f3;
      height: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .form {
        width: 460px;

        &-title {
          font-size: 32px;
          font-weight: bold;
          margin-bottom: 10px;
        }

        .sub-title {
          opacity: 0.6;
          font-weight: normal;
          margin-bottom: 20px;
        }

        .email-field,
        .password-field,
        .MuiInput-root {
          width: 100%;
          margin-bottom: 5px;
          &.err {
            border-color: var(--error, #FF0D00);
            ;
          }
        }

        .validate_input {
          display: flex;
          flex-direction: column;

          .err {
            border: 1px solid;
            border-color: var(--error, #FF0D00);
          }
        }

        .error_text {
          margin-left: 10px;
          display: flex;
          width: 451px;
          height: 18px;
          flex-direction: column;
          justify-content: center;
          color: var(--error, #FF0D00);
          font-family: Raleway;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .helpfullText {
          color: #42454C;
          opacity: 50%;
          font-size: 10px;
          margin-left: 12px;
          width: 424px;
          position: relative;
          bottom: 8px;
        }


        .MuiInput-root {
          font-size: 12px;
          height: 32px;
          background: #ffffff;
          border: none;
          border-radius: 4px;
          padding: 8px 12px;
          outline: none;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
            0px 0px 1px rgba(0, 0, 0, 0.04);

          &::placeholder {
            color: rgba(66, 69, 76, 0.5);
          }

          // ADD NEW BS COLOR
          &:focus {
            box-shadow: 1px 0px 3px 1px #3f73f9;
          }
        }

        .btn-actions {
          margin-top: 20px;

          .Button {
            width: 100%;
          }

          .primary {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}