.Dropdown {
  position: relative;

  &-content {
    position: absolute;
    top: calc(100% + 23px);
    right: 0;
    background: #ffffff;
    box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04),
      0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
      0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    padding: 10px 0;
    min-width: 160px;
    z-index: 1;
  }
}
